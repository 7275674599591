import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import s from './Sort.module.scss';

const Sort = ({currSort, onChangeHandler}) => {
    const {t} = useTranslation();

    const items = ['relevance', 'date', 'alphabetically'];
    return (
        <div className={s['SearchSort']}>
            <label className="sr-only" htmlFor="search-sort">
                {t('search.sort.label')}
            </label>
            <select
                className={s['SearchSort__Select']}
                name="sort"
                id="search-sort"
                value={currSort}
                onChange={(e) => onChangeHandler(e.target.value)}
            >
                {items.map((value, index) => (
                    <option
                        key={index}
                        value={value}
                    >{t(`search.sort.options.${value}`)}</option>
                ))}
            </select>
        </div>
    );
};

Sort.propTypes = {
    currSort: PropTypes.string,
    onChangeHandler: PropTypes.func,
};

Sort.defaultProps = {
    currSort: '',
    onChangeHandler: () => {},
};

export default Sort;
