import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import Sort from '../Sort';
import List from '../List';
import s from './Result.module.scss';

const Result = ({
    apiUrl,
    limit,
    page,
    list,
    options,
    inputName,
    currCount,
    currFilter,
    currTab,
    currPage,
    currSort,
    setCurrPage,
    setCurrSort,
    extraArgs,
    isGlobalSearch,
    archiveLinks,
    paginateLoadMore,
    loading,
    backendLoaded,
    setIsEmpty,
    setLoading,
    getUrl,
}) => {
    const {t} = useTranslation();

    const isSingleCount = currTab === null || typeof(currCount) !== 'object';
    const defaultCount = isSingleCount ? currCount : _.get(currCount, currTab, 0);

    const [items, setItems] = useState(list);
    const [initialLoaded, setInitialLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [currListCount, setCurrListCount] = useState(defaultCount);

    useEffect(() => {
        if(!_.isEmpty(currCount) || currCount > 0) {
            if(defaultCount !== currListCount) {
                setCurrListCount(defaultCount);
            }
        }
    }, [currCount, currTab]);

    // Map params for api request
    const mapParams = (key, val, isArr = false) => {
        if(Array.isArray(val)) {
            return val.map(v => mapParams(key, v, true)).join('&');
        } else {
            const newKey = encodeURIComponent(key);
            const newVal = encodeURIComponent(val);
            const arrKey = isArr ? '[]' : '';
            return `${newKey}${arrKey}=${newVal}`;
        }
    };

    const createQuery = (params) => {
        const q = Object.keys(params).map(k => mapParams(k, params[k]));
        const str = q.filter(v => !_.isEmpty(v)).join('&');
        return str !== '' ? `?${str}` : '';
    };

    // Delay result so loader is shown a minimum amount of time
    const delayCallback = (start, delay, callback) => {
        const finished = new Date().getTime();
        const timeout = Math.max(delay - (finished - start), 0);
        setTimeout(() => {
            callback();
        }, timeout);
    };

    const query = createQuery({
        _type: currTab, // Must be first
        ...currFilter,
        ...extraArgs,
        _limit: limit,
        _page: currPage,
        _sort: currSort,
    });
    const url = `${apiUrl}${query}`;

    useEffect(() => {
        if(backendLoaded) {
            setInitialLoaded(true);
        }
    }, []);

    const updateList = useCallback(() => {
        if(backendLoaded && !initialLoaded) {
            return;
        }
        setLoading(true);
        const startTime = new Date().getTime();
        fetch(url).then(res => new Promise(resolve => {
            const totalItems = parseInt(res.headers.get('x-total-count'));
            setCurrListCount(totalItems);
            resolve(res.json());
        })).then(res => new Promise(resolve => {
            const isError = res.length === undefined;
            const newItems = !isError ? res : [];

            if(paginateLoadMore) {
                setLoading(false);
                setItems([...items, ...newItems]);
            } else {
                setItems(newItems);
            }
            setInitialLoaded(true);
            setError(isError);
            delayCallback(startTime, 1500, () => {
                resolve(res);
            });
        })).then(() => {
            setLoading(false);
        }, () => {
            setLoading(false);
            setError(true);
            setItems([]);
            setCurrListCount(0);
        });
    }, [url]);
    useEffect(updateList, [updateList]);

    useEffect(() => {
        if(initialLoaded && page === 1 && _.isEmpty(items)) {
            setIsEmpty(true);
        }
    }, [items, initialLoaded]);

    let pagination = {};
    const totalPages = currListCount === 0 ? 1 : Math.ceil(currListCount / limit);
    if(totalPages > 1) {
        pagination = {
            currPage: currPage,
            totalPages: totalPages,
            getUrl: (newParams) => getUrl(newParams),
            onPageChange: ({selected}) => setCurrPage(selected+1),
        };
    }

    const currKeyword = _.get(currFilter, inputName);
    const currTabName = _.toLower(t(`search.tabs.names.${currTab}`));
    const subtitle = currListCount === 0 ? '' : (
        !_.isEmpty(currKeyword) ? t('search.subtitleKeyword', {
            keyword: currKeyword,
            count: currListCount,
            type: currTabName,
        }) : t('search.subtitle', {count: currListCount, type: currTabName})
    );

    const TitleTag = isGlobalSearch ? 'h2' : 'h3';

    return (
        <div className={s['SearchResult']}>
            {isGlobalSearch &&
                <div className={s['SearchResult__Header']}>
                    <TitleTag className={s['SearchResult__Title']}>
                        {subtitle}
                    </TitleTag>
                    {!_.isEmpty(currSort) &&
                        <Sort
                            currSort={currSort}
                            onChangeHandler={(val) => setCurrSort(val)}
                        />
                    }
                </div>
            }

            <div className={s['SearchResult__List']}>
                <List
                    type={currTab}
                    loading={loading}
                    items={items}
                    error={error}
                    pagination={pagination}
                    isLoadMore={paginateLoadMore}
                    archiveLinks={archiveLinks}
                    options={options}
                />
            </div>
        </div>
    );
};

Result.propTypes = {
    apiUrl: PropTypes.string,
    baseUrl: PropTypes.string,
    limit: PropTypes.number,
    page: PropTypes.number,
    sort: PropTypes.string,
    list: PropTypes.array,
    inputName: PropTypes.string,
    extraArgs: PropTypes.object,
    options: PropTypes.object,
    isGlobalSearch: PropTypes.bool,
    archiveLinks: PropTypes.object,
    paginateLoadMore: PropTypes.bool,
    currCount: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
    ]),
    currFilter: PropTypes.object,
    currTab: PropTypes.string,
    currPage: PropTypes.number,
    currSort: PropTypes.string,
    loading: PropTypes.bool,
    backendLoaded: PropTypes.bool,
    setCurrPage: PropTypes.func,
    setCurrSort: PropTypes.func,
    setLoading: PropTypes.func,
    setIsEmpty: PropTypes.func,
    getUrl: PropTypes.func,
};

Result.defaultProps = {
    apiUrl: '',
    baseUrl: '',
    limit: 6,
    page: 1,
    sort: null,
    list: [],
    inputName: 'search',
    extraArgs: {},
    options: {},
    isGlobalSearch: false,
    archiveLinks: null,
    paginateLoadMore: false,
    currCount: 0,
    currFilter: {},
    currTab: null,
    currPage: 1,
    currSort: null,
    loading: false,
    backendLoaded: true,
    setCurrPage: () => {},
    setCurrSort: () => {},
    setLoading: () => {},
    setIsEmpty: () => {},
    getUrl: () => {},
};

export default Result;
