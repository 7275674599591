import React, {Fragment, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import Paginate from 'react-paginate';
import ArrowRight from 'Assets/svg/arrowRight.svg';
import ArrowLeft from 'Assets/svg/arrowLeft.svg';
import s from './Pagination.module.scss';

const Pagination = ({currPage, totalPages, getUrl, onPageChange}) => {
    const {t} = useTranslation();

    const ref = useRef();

    useEffect(() => {
        addRelAttributes();
    }, []);

    const addRelAttributes = () => {
        if(ref && ref.current) {
            const prevBtn = ref.current.querySelector('.Pagination__Item--Prev a');
            if(prevBtn) {
                prevBtn.setAttribute('rel', 'prev');
            }
            const nextBtn = ref.current.querySelector('.Pagination__Item--Next a');
            if(nextBtn) {
                nextBtn.setAttribute('rel', 'next');
            }
        }
    };

    const hrefBuilder = (p) => {
        return getUrl({paged: p.toString()});
    };

    const prev = (
        <Fragment>
            <ArrowLeft />
            <span className="sr-only">{t('pagination.previousLabel')}</span>
        </Fragment>
    );
    const next = (
        <Fragment>
            <ArrowRight />
            <span className="sr-only">{t('pagination.nextLabel')}</span>
        </Fragment>
    );

    const breakClasses = classNames(
        s['Pagination__Item'],
        s['Pagination__Item--Break'],
    );
    const nextClasses = classNames(
        s['Pagination__Item'],
        s['Pagination__Item--Next'],
        'Pagination__Item--Next', // For querySelector above
    );
    const prevClasses = classNames(
        s['Pagination__Item'],
        s['Pagination__Item--Prev'],
        'Pagination__Item--Prev', // For querySelector above
    );

    return (
        <div className={s['Pagination']} ref={ref}>
            <Paginate
                pageCount={totalPages}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                forcePage={currPage-1}
                hrefBuilder={hrefBuilder}
                onPageChange={onPageChange}
                disableInitialCallback={true}
                containerClassName={s['Pagination__List']}
                pageClassName={s['Pagination__Item']}
                breakClassName={breakClasses}
                activeClassName={s['Pagination__Item--Active']}
                nextClassName={nextClasses}
                previousClassName={prevClasses}
                disabledClassName={s['Pagination__Item--Disabled']}
                breakLinkClassName={s['Pagination__Break']}
                pageLinkClassName={s['Pagination__Link']}
                previousLinkClassName={s['Pagination__Link']}
                nextLinkClassName={s['Pagination__Link']}
                nextLabel={next}
                previousLabel={prev}
            />
        </div>
    );
};

Pagination.propTypes = {
    currPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    getUrl: PropTypes.func,
    onPageChange: PropTypes.func,
};

Pagination.defaultProps = {
    currPage: 1,
    totalPages: 1,
    getUrl: () => {},
    onPageChange: null,
};

export default Pagination;
