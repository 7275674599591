import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import Cross from 'Assets/svg/cross.svg';
import Select from './../Select';
import Tags from './../Tags';
import Field from './../Field';
import s from './Filter.module.scss';

const Filter = ({
    type,
    showInput,
    inputName,
    filters,
    defaultFilter,
    currFilter,
    setFilter,
    updateParams,
}) => {
    const { t } = useTranslation();

    // Keep input as local state so it doesn't trigger update of list on change
    const defaultInput = _.get(defaultFilter, inputName, '');
    const [input, setInput] = useState(defaultInput);

    // Update input state if changed in parent component
    const currInput = _.get(currFilter, inputName, '');
    useEffect(() => {
        if(currInput !== input) {
            setInput(currInput);
        }
    }, [currInput]);

    const updateFilter = (name, value) => {
        setFilter(state => ({...state, [name]: value}));
    };

    const resetHandler = (e) => {
        e.preventDefault();

        // Reset input and some params,
        // this will only trigger one state update instead of 3
        setFilter({});
        setInput('');
        updateParams('paged', 1);
    };

    const submitHandler = (e) => {
        e.preventDefault();

        // Update input and reset some params,
        // this will only trigger one state update instead of 3
        updateFilter(inputName, input);
        updateParams('paged', 1);
        updateParams('sort',  'relevance');
    };

    const firstFilter = !_.isEmpty(filters) ? filters[0] : {};

    // If input is visible, tags won't fit, only select is allowed
    const inputInTop = type !== 'architecture_prize' && type !== 'member_company';
    const showFirstFilterInTop = showInput && firstFilter.type !== 'tags' && inputInTop;
    const topFilter = showFirstFilterInTop ? firstFilter : {};
    const otherFilters = [...filters];
    if(showFirstFilterInTop) {
        otherFilters.shift();
    }

    const tagFilters = otherFilters.filter(({type}) => type === 'tags');
    const selectFilters = otherFilters.filter(({type}) => type !== 'tags');

    const classes = classNames(
        [s['SearchFilter']],
        [s[`SearchFilter--${_.upperFirst(type)}`]],
    );

    return (
        <div className={classes}>
            <form
                className={s['SearchFilter__Form']}
                onReset={(e) => resetHandler(e)}
                onSubmit={(e) => submitHandler(e)}
            >
                <div className={s['SearchFilter__Top']}>
                    {showInput &&
                        <div className={s['SearchFilter__Field']}>
                            <Field
                                type={type}
                                input={input}
                                name={inputName}
                                setInput={setInput}
                            />
                        </div>
                    }

                    {showFirstFilterInTop && !_.isEmpty(topFilter) &&
                        <div className={s['SearchFilter__FirstFilter']}>
                            <FilterType
                                filter={topFilter}
                                defaultFilter={defaultFilter}
                                currFilter={currFilter}
                                updateFilter={updateFilter}
                            />
                        </div>
                    }
                </div>

                {!_.isEmpty(selectFilters) &&
                    <div className={s['SearchFilter__Selects']}>
                        {selectFilters.map((filter, index) => (
                            <div className={s['SearchFilter__SelectItem']} key={index}>
                                <FilterType
                                    filter={filter}
                                    defaultFilter={defaultFilter}
                                    currFilter={currFilter}
                                    updateFilter={updateFilter}
                                />
                            </div>
                        ))}
                    </div>
                }

                {!_.isEmpty(tagFilters) &&
                    <div className={s['SearchFilter__Tags']}>
                        {tagFilters.map((filter, index) => (
                            <div className={s['SearchFilter__TagItem']} key={index}>
                                <FilterType
                                    filter={filter}
                                    defaultFilter={defaultFilter}
                                    currFilter={currFilter}
                                    updateFilter={updateFilter}
                                />
                            </div>
                        ))}
                    </div>
                }

                <button 
                    className={s['SearchFilter__Submit']}
                    type="submit"
                >
                    {t('search.filter.submit')} 
                </button>

                <button
                    className={s['SearchFilter__Reset']}
                    type="reset"
                >
                    <Cross />
                    {t('search.filter.reset')}
                </button>
            </form>
        </div>
    );
};

Filter.propTypes = {
    type: PropTypes.string,
    showInput: PropTypes.bool,
    inputName: PropTypes.string,
    filters: PropTypes.array,
    defaultFilter: PropTypes.object,
    currFilter: PropTypes.object,
    setFilter: PropTypes.func,
    updateParams: PropTypes.func,
};

Filter.defaultProps = {
    type: '',
    showInput: true,
    inputName: '',
    filters: [],
    defaultFilter: {},
    currFilter: {},
    setFilter: null,
    updateParams: () => {},
};

const FilterType = ({filter, defaultFilter, currFilter, updateFilter}) => {
    const {type} = filter;
    const types = {
        tags: Tags,
        select: Select,
        multiselect: Select,
    };
    const Component = types[type];
    const classes = classNames(
        [s['SearchFilter__Type']],
        [s[`SearchFilter__Type--${_.upperFirst(type)}`]],
    );
    return (
        <div className={classes}>
            <Component 
                {...filter}
                defaultFilter={defaultFilter}
                currFilter={currFilter}
                updateFilter={updateFilter}
            />
        </div>
    );
};

FilterType.propTypes = {
    filter: PropTypes.object,
    defaultFilter: PropTypes.object,
    currFilter: PropTypes.object,
    updateFilter: PropTypes.func,
};

FilterType.defaultProps = {
    filter: {},
    defaultFilter: {},
    currFilter: {},
    updateFilter: null,
};

export default Filter;
