import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import classNames from 'classnames';
import {sanitizeText, sanitizeParagraphs} from 'utils/html';
import External from 'Assets/svg/external.svg';
import Mail from 'Assets/svg/mail.svg';
import Phone from 'Assets/svg/phone.svg';
import s from './CardMemberCompany.module.scss';

const CardMemberCompany = ({
    title,
    url,
    specializationIds,
    specializations,
    countyId,
    counties,
    logo,
    address,
    city,
    email,
    mobile,
    phone,
    postalCode,
    workPhone,
}) => {
    const {t} = useTranslation();

    const sanitizedTitle = sanitizeText(title);

    const postalAddress = [postalCode, city].filter((c) => !_.isEmpty(c)).join(' ');
    const addressStr = [address, postalAddress].filter((a) => !_.isEmpty(a)).join('<br>');

    const website = url.replace('https://', '').replace('http://', '');

    const phoneNumbers = [
        {label: t('cardMemberCompany.phone'), value: phone},
        {label: t('cardMemberCompany.mobile'), value: mobile},
        {label: t('cardMemberCompany.workPhone'), value: workPhone},
    ];

    const county = _.isEmpty(counties) || _.isEmpty(countyId) ? '' : _.get(counties, countyId, '');

    const specializationArr = _.isEmpty(specializations) || _.isEmpty(specializationIds) ? [] : (
        specializationIds.map((s) => _.get(specializations, s, s))
    );
    const displayLabel = specializationArr.join(', ');

    return (
        <article className={s['CardMemberCompany']}>

            <div className={s['CardMemberCompany__Content']}>

                {!_.isEmpty(logo) &&
                    <div className={s['CardMemberCompany__Logo']}>
                        <img
                            src={logo}
                            alt={unescape(sanitizedTitle)}
                        />
                    </div>
                }

                {displayLabel &&
                    <div className={s['CardMemberCompany__Label']}>{displayLabel}</div>
                }

                <TitleLink url={url}>
                    <h3
                        className={s['CardMemberCompany__Title']}
                        dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                    />
                </TitleLink>

                {addressStr &&
                    <div
                        className={s['CardMemberCompany__Text']}
                        dangerouslySetInnerHTML={{__html: sanitizeParagraphs(addressStr)}}
                    />
                }
                {website &&
                    <a
                        className={classNames(s['CardMemberCompany__Link'], s['CardMemberCompany__Link--Website'])}
                        href={url}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <span className={s['CardMemberCompany__LinkText']}>{website}</span>
                        <External />
                    </a>
                }
                {email &&
                    <a
                        className={classNames(s['CardMemberCompany__Link'], s['CardMemberCompany__Link--Mail'])}
                        href={`mailto:${email}`}
                        title={email}
                    >
                        <Mail />
                        <span className={s['CardMemberCompany__LinkText']}>{email}</span>
                    </a>
                }
                {phoneNumbers.filter(({value}) => !_.isEmpty(value)).map(({label, value}, index) => {
                    const parsedPhone = value.replace(/^(?:(\+|00)?46|0)?/, '0046').replace(/[-\s]/g, '');
                    return (
                        <a
                            className={classNames(s['CardMemberCompany__Link'], s['CardMemberCompany__Link--Phone'])}
                            href={`tel:${parsedPhone}`}
                            title={parsedPhone}
                            key={index}
                        >
                            <Phone />
                            <span className="sr-only">{label}</span>
                            <span className={s['CardMemberCompany__LinkText']}>{value}</span>
                        </a>
                    );
                })}
            </div>

            {!_.isEmpty(county) &&
                <div className={s['CardMemberCompany__Footer']}>
                    <div className={s['CardMemberCompany__County']}>
                        <span className="sr-only">{t('cardArchitectOffice.county')}</span>
                        {county}
                    </div>
                </div>
            }

        </article>
    );
};

CardMemberCompany.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    specializationIds: PropTypes.array,
    specializations: PropTypes.object,
    countyId: PropTypes.string,
    counties: PropTypes.object,
    logo: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    email: PropTypes.string,
    fax: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
    postalCode: PropTypes.string,
    workPhone: PropTypes.string,
};

CardMemberCompany.defaultProps = {
    title: '',
    url: '',
    specializationIds: [],
    specializations: {},
    countyId: '',
    counties: {},
    logo: '',
    address: '',
    city: '',
    email: '',
    fax: '',
    mobile: '',
    phone: '',
    postalCode: '',
    workPhone: '',
};

const TitleLink = ({url, children}) => {
    if(_.isEmpty(url)) {
        return children;
    }
    return (
        <a
            className={s['CardMemberCompany__TitleLink']}
            href={url}
            target="_blank"
            rel="noreferrer noopener"
        >
            {children}
        </a>
    );
};

TitleLink.propTypes = {
    children: PropTypes.object,
    url: PropTypes.string,
};

TitleLink.defaultProps = {
    children: null,
    url: '',
};

export default CardMemberCompany;