import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import Pagination from 'Components/Pagination';
import CardArchitectOffice from 'Components/CardArchitectOffice';
import CardArchitectProject from 'Components/CardArchitectProject';
import CardArchitecturePrize from 'Components/CardArchitecturePrize';
import CardBlog from 'Components/CardBlog';
import CardBlogPost from 'Components/CardBlogPost';
import CardCompetition from 'Components/CardCompetition';
import CardCompetitionEntry from 'Components/CardCompetitionEntry';
import CardCourse from 'Components/CardCourse';
import CardEvent from 'Components/CardEvent';
import CardLandscapeArchitect from 'Components/CardLandscapeArchitect';
import CardNews from 'Components/CardNews';
import CardPage from 'Components/CardPage';
import CardMemberCompany from 'Components/CardMemberCompany';
import s from './List.module.scss';

const List = ({type, loading, error, items, pagination, isLoadMore, archiveLinks, options}) => {
    const {t} = useTranslation();

    const showList = !loading && !error && !_.isEmpty(items);
    const showError = !loading && error;
    const showNoResult = !loading && !error && _.isEmpty(items);

    const archiveLink = _.get(archiveLinks, type);
    const archiveLinkText = t(`search.archive.${type}`);

    const page = _.get(pagination, 'currPage');
    const totalPages = _.get(pagination, 'totalPages');

    const showPagination = !isLoadMore && !_.isEmpty(pagination);
    const showLoadMore = isLoadMore && !_.isEmpty(pagination) && page < totalPages;

    // Will show tab on cards for news, blog posts, architect projects and landscape
    const itemShowTab = type === 'global' || type === 'architect_project';

    return (
        <div className={s['SearchList']}>
            {loading &&
                <Loader />
            }

            {showList &&
                <div className={s['SearchList__List']}>
                    {items.map((item, index) => (
                        <Item
                            key={index}
                            type={type}
                            item={item}
                            showTab={itemShowTab}
                            options={options}
                        />
                    ))}
                </div>
            }
            {showNoResult &&
                <div className={s['SearchList__NoResult']}>
                    {t([`search.list.${type}.noResult`, 'search.list.noResult'])}
                </div>
            }
            {showError &&
                <div className={s['SearchList__Error']}>
                    {t('search.list.error')}
                </div>
            }

            {((showList && (showPagination || showLoadMore)) || !_.isEmpty(archiveLink)) &&
                <div className={s['SearchList__Footer']}>
                    {showPagination &&
                        <div className={s['SearchList__Pagination']}>
                            <Pagination
                                {...pagination}
                            />
                        </div>
                    }

                    {showLoadMore &&
                        <button
                            type="button"
                            className={s['SearchList__LoadMore']}
                            onClick={() => pagination.onPageChange({selected: page})}
                        >
                            {t('search.list.loadMore')}
                        </button>
                    }

                    {!_.isEmpty(archiveLink) &&
                        <a className={s['SearchList__Button']} href={archiveLink}>
                            {archiveLinkText}
                        </a>
                    }
                </div>
            }
        </div>
    );
};

List.propTypes = {
    type: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    items: PropTypes.array,
    pagination: PropTypes.object,
    isLoadMore: PropTypes.bool,
    archiveLinks: PropTypes.object,
    options: PropTypes.object,
};

List.defaultProps = {
    type: '',
    loading: false,
    error: false,
    items: [],
    pagination: {},
    isLoadMore: false,
    archiveLinks: null,
    options: {},
};

const Item = ({type, item, showTab, options}) => {
    const cards = {
        blog: CardBlog,
        'blog-post': CardBlogPost,
        course: CardCourse,
        event: CardEvent,
        news: CardNews,
        page: CardPage,
        'architect-office': CardArchitectOffice,
        'architect-project': CardArchitectProject,
        'architecture-prize': CardArchitecturePrize,
        'competition': CardCompetition,
        'competition-entry': CardCompetitionEntry,
        'landscape-architect': CardLandscapeArchitect,
        'member-company': CardMemberCompany,
    };
    const cardType = _.get(item, 'type', type);
    const Card = _.get(cards, cardType, CardNews);

    return(
        <div className={s['SearchList__Item']}>
            <Card
                {...options}
                {...item}
                showTab={showTab}
            />
        </div>
    );
};

Item.propTypes = {
    type: PropTypes.string,
    item: PropTypes.object,
    showTab: PropTypes.bool,
    options: PropTypes.object,
};

Item.defaultProps = {
    type: '',
    item: {},
    showTab: false,
    options: {},
};

const Loader = () => {
    const {t} = useTranslation();
    return (
        <div className={s['SearchList__Loader']}>
            <span></span>
            <span className="sr-only">{t('search.list.loader')}</span>
        </div>
    );
};

Loader.propTypes = {
};

Loader.defaultProps = {
};

export default List;
