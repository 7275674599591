import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import s from './Tabs.module.scss';

const Tabs = ({currTab, currCount, onClickHandler}) => {
    const items = [
        'global', 'page', 'competition', 'course', 'event',
        'architecture_prize', 'news', 'blog',
    ];
    return (
        <div className={s['SearchTabs']}>
            <div className={s['SearchTabs__Container']}>
                <div className={s['SearchTabs__List']}>
                    {items.map((type, index) => (
                        <Item
                            key={index}
                            type={type}
                            currTab={currTab}
                            currCount={currCount}
                            onClickHandler={onClickHandler}
                        />
                    ))}

                    <div className={classNames(s['SearchTabs__Item'], s['SearchTabs__Item--Placeholder'])} />
                </div>
            </div>
        </div>
    );
};

Tabs.propTypes = {
    currTab: PropTypes.string,
    currCount: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
    ]),
    onClickHandler: PropTypes.func,
};

Tabs.defaultProps = {
    currTab: '',
    currCount: {},
    onClickHandler: () => {},
};

const Item = ({type, currTab, currCount, onClickHandler}) => {
    const {t} = useTranslation();

    const count = _.get(currCount, type, null);
    const name = t(`search.tabs.names.${type}`);

    const classes = classNames(
        [s['SearchTabs__Item']],
        {[s['SearchTabs__Item--Active']]: currTab === type},
        {[s['SearchTabs__Item--Empty']]: count === 0},
        {[s['SearchTabs__Item--Loading']]: count === null},
    );
    return (
        <button
            type="button"
            className={classes}
            onClick={() => onClickHandler(type)}
        >{name}<span className={s['SearchTabs__Count']}> ({count})</span></button>
    );
};

Item.propTypes = {
    type: PropTypes.string,
    currTab: PropTypes.string,
    currCount: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
    ]),
    onClickHandler: PropTypes.func,
};

Item.defaultProps = {
    type: '',
    currTab: '',
    currCount: {},
    onClickHandler: () => {},
};

export default Tabs;
