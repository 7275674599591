import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import s from './ReactSelect.module.scss';

const ReactSelect = ({
    className,
    ...restProps
}) => {
    const classes = classNames(
        s.Root,
        className,
    );

    return (
        <div className={classes}>
            <Select
                className="react-select"
                classNamePrefix="react-select"
                {...restProps}
            />
        </div>
    );
};

ReactSelect.propTypes = {
    className: PropTypes.string,
};

export default ReactSelect;
