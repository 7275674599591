import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import s from './Field.module.scss';

const Field = ({type, input, name, setInput}) => {
    
    const { t } = useTranslation();

    const placeholder = t([`search.field.placeholder.${type}`, 'search.field.placeholder.default']);
    const label = t([`search.field.label.${type}`, 'search.field.label.default']);

    const classes = classNames(
        [s['SearchField']],
        [s[`SearchField--${_.upperFirst(type)}`]],
        {[s['SearchField--HasValue']]: !_.isEmpty(input)},
    );

    return (
        <div className={classes}>
            <input
                className={s['SearchField__Input']}
                id="search"
                name={name}
                value={input}
                placeholder={placeholder}
                onChange={e => setInput(e.target.value)}
            />
            <label htmlFor="search" className={s['SearchField__Label']}>
                {label}
            </label>
        </div>
    );
};

Field.propTypes = {
    type: PropTypes.string,
    input: PropTypes.string,
    setInput: PropTypes.func,
    name: PropTypes.string,
};

Field.defaultProps = {
    type: '',
    input: '',
    setInput: null,
    name: 'search',
};

export default Field;
