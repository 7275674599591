import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import s from './Tags.module.scss';

const Tags = ({ name, title, options, currFilter, updateFilter }) => {
    const {t, i18n} = useTranslation();
    const selected = currFilter[name] ? currFilter[name] : [];

    let label = title ? title : '';
    if(i18n.exists(`search.filter.${name}.title`)) {
        label = t(`search.filter.${name}.title`);
    }

    const changeHandler = (value, isSelected) => {
        if(isSelected) {
            updateFilter(name, [...selected].filter(v => v !== value));
        } else {
            updateFilter(name, [...selected, value]);
        }
    };

    const tags = options.map(({label, value}) => {
        let optLabel = label ? label : '';
        if(optLabel === '' && i18n.exists(`search.filter.${name}.options.${value}`)) {
            optLabel = t(`search.filter.${name}.options.${value}`);
        }
        return {label: optLabel, value: value};
    });

    const classes = classNames(
        [s['SearchTags']],
        [s[`SearchTags--${_.upperFirst(name)}`]],
    );

    return (
        <div className={classes}>
            {label &&
                <h3 className={s['SearchTags__Label']}>{label}</h3>
            }

            <div className={s['SearchTags__List']}>
                {tags.map(({label, value}, i) => {
                    const isSelected = selected.indexOf(value) !== -1;
                    const itemClasses = classNames(
                        [s['SearchTags__Item']],
                        {[s['SearchTags__Item--Active']]: isSelected},
                    );
                    const identifier = `${name}_${value}`;
                    return (
                        <div
                            key={i}
                            htmlFor={identifier}
                            className={itemClasses}
                        >
                            <input
                                className={s['SearchTags__Input']}
                                type="checkbox"
                                name={name}
                                value={value}
                                id={identifier}
                                onChange={() => changeHandler(value, isSelected)}
                            />
                            <label
                                htmlFor={identifier}
                                dangerouslySetInnerHTML={{__html: sanitizeText(label)}}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

Tags.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    options: PropTypes.array,
    currFilter: PropTypes.object,
    updateFilter: PropTypes.func,
};

Tags.defaultProps = {
    name: '',
    title: '',
    options: [],
    currFilter: {},
    updateFilter: null,
};

export default Tags;
